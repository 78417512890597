html {
  --main-bg-color: #f7f7f9;
  --main-color: #7abe43;
  --alter-color: #3d5971;
}

html,
body {
  max-width: 100%;
  overflow-x: hidden;
}
.manager-ul {
  margin-right: 2rem;
}
.dropdown-custom {
  background-color: var(--alter-color);
}

.dropdown-item-custom {
  color: white;
}

.dropdown-custom > a:hover {
  background-color: var(--alter-color);
  color: white;
  -webkit-box-shadow: inset 0px -1px 0px 0px var(--main-color);
  -moz-box-shadow: inset 0px -1px 0px 0px var(--main-color);
  box-shadow: inset 0px -1px 0px 0px var(--main-color);
}

.dropdown-custom > a:active {
  color: var(--main-color);
}

p {
  font-size: 13px;
}

.map-container-9,
.map-container-10,
.map-container-11 {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
}
.map-container-9 iframe,
.map-container-10 iframe,
.map-container-11 iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}

@font-face {
  font-family: "DejaVuSans";
  src: url("./fonts/DejaVuSans.woff") format("woff"),
    url("./fonts/DejaVuSans.eot?#iefix") format("embedded-opentype"),
    url("./fonts/DejaVuSans.ttf") format("truetype"),
    url("./fonts/DejaVuSans.svg#DejaVuSans") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "BebasNeueRegular";
  src: url("./fonts/BebasNeueRegular.woff") format("woff"),
    url("./fonts/BebasNeueRegular.eot?#iefix") format("embedded-opentype"),
    url("./fonts/BebasNeueRegular.ttf") format("truetype"),
    url("./fonts/BebasNeueRegular.svg#BebasNeueRegular") format("svg");
  font-weight: normal;
  font-style: normal;
}

.dejavu {
  font-family: "DejaVuSans", Helvetica, Arial, Sans-Serif;
  font-size: 13px;
}

body {
  font-family: "BebasNeueRegular", Helvetica, Arial, Sans-Serif;
}

p {
  font-family: "DejaVuSans", Helvetica, Arial, Sans-Serif;
  font-size: 13px;
}

.card-emg:hover {
  border: 1px solid var(--main-color);
}

.certs {
  position: absolute;
  right: 80px;
  bottom: 0;
  justify-content: center;
  z-index: 99;
  color: white;
  width: 10vh;
  height: 10vh;
}

.ml-10 {
  margin-left: 140px;
}
.navv {
  display: flex;
  align-items: center;
  opacity: 0.9;
  color: white;
}

.location {
  padding: 1rem;
}

.special {
  list-style: none !important;
}
.nav-links {
  display: flex;
  justify-content: space-around;
  width: 35%;
}

.nav-links li {
  list-style: none;
}

.nav-link {
  color: white !important;
  text-decoration: none;
  letter-spacing: 1px;
  font-weight: 500;
  font-size: 14px;
}

.burger {
  display: none;
}

.row {
  margin-right: 0;
  margin-left: 0;
}

.burger div {
  width: 25px;
  height: 3px;
  background-color: white;
  margin: 5px;
}

.bg-emg-blue {
  background-color: var(--alter-color);
}

.bg-emg-green {
  background-color: var(--main-color);
}

* {
  box-sizing: border-box;
}
.search {
  width: 40px;
  height: 40px;
  position: relative;
  overflow: hidden;
  transition: all 0.5s ease;
}
.search:before {
  content: "";
  display: block;
  width: 1px;
  height: 100%;
  position: relative;
  background-color: var(--main-color);
  transition: all 0.5s ease;
}
.search.open {
  background-color: white;
  width: 200px;
}

.search.open input {
  color: black;
}
.search.open:before {
  height: 48px;
  position: absolute;
}
.search-box {
  width: 100%;
  height: 100%;
  box-shadow: none;
  border: none;
  background: transparent;
  color: #fff;
  padding: 8px 40px 8px 18px;
  font-size: 16px;
}
.search-box:focus {
  outline: none;
}
.search-button {
  width: 40px;
  height: 40px;
  display: block;
  position: absolute;
  right: 0;
  top: 0;
  padding: 8px;
  cursor: pointer;
}
.search-icon {
  width: 16px;
  height: 16px;
  border-radius: 16px;
  border: 2px solid var(--main-color);
  display: block;
  position: relative;
  transition: all 0.5s ease;
}

.in-ul {
  padding: 0;
}
.search-icon:before {
  content: "";
  width: 2px;
  height: 6px;
  position: absolute;
  right: -0.8px;
  top: 12px;
  display: block;
  background-color: var(--main-color);
  transform: rotate(-45deg);
  transition: all 0.5s ease;
}
.search-icon:after {
  content: "";
  width: 1.2px;
  height: 6px;
  position: absolute;
  right: -4.8px;
  top: 16px;
  display: block;
  background-color: var(--main-color);
  transform: rotate(-45deg);
  transition: all 0.5s ease;
}
.open .search-icon {
  width: 24px;
  height: 24px;
  border-radius: 24px;
}
.open .search-icon:before {
  transform: rotate(125deg);
  right: 10.8px;
  top: 10.2px;
  height: 6.4px;
}
.open .search-icon:after {
  transform: rotate(60deg);
  right: 10.8px;
  top: 6.2px;
  height: 7.2px;
}

.navbar-nav > li {
  margin-right: 5px;
}

.logoes {
  height: 30vh;
}

.partner-img {
  max-height: 30vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
}

.line-emg {
  border: 1px solid var(--main-color);
}

.border-emg {
  -webkit-box-shadow: inset 0px 0px 0px 1px var(--main-color);
  -moz-box-shadow: inset 0px 0px 0px 1px var(--main-color);
  box-shadow: inset 0px 0px 0px 1px var(--main-color);
}
.thumbnail {
  height: 30vh;
  background-image: url("res/background.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.min-vh-25 {
  min-height: 25vh;
}

.btn:focus,
.btn:active {
  -webkit-box-shadow: 0px 0px 10px 1px var(--alter-color);
  -moz-box-shadow: 0px 0px 10px 1px var(--alter-color);
  box-shadow: 0px 0px 10px 1px var(--alter-color);
}

.border-color-emg {
  border-color: var(--main-color) !important;
}

ul {
  list-style: none; /* Remove default bullets */
}

.news-card {
  margin: 0 10px 10px 0 !important;
}

.management > .special::before {
  content: "\2022"; /* Add content: \2022 is the CSS Code/unicode for a bullet */
  color: var(--main-color); /* Change the color */
  font-weight: bold; /* If you want it to be bold */
  display: inline-block; /* Needed to add space between the bullet and the text */
  width: 1em; /* Also needed for space (tweak if needed) */
  margin-left: -1em; /* Also needed for space (tweak if needed) */
}

.management > .deph::before {
  content: "\02010"; /* Add content: \2022 is the CSS Code/unicode for a bullet */
  color: #000; /* Change the color */

  display: inline-block; /* Needed to add space between the bullet and the text */
  width: 1em; /* Also needed for space (tweak if needed) */
  margin-left: -1em; /* Also needed for space (tweak if needed) */
}

.slider {
  height: 100vh;
  background-image: url("res/background.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.currentPageTitle {
  font-size: 2rem;
  margin-top: 2rem;
  margin-bottom: 2.7rem;
}

.currentPageTitle::first-letter {
  color: var(--main-color);
  font-size: 150%;
  border-bottom: 2px solid var(--main-color);
}

.color-emg {
  color: var(--main-color);
}
.color-emg-dark {
  color: var(--alter-color);
}

.map-button:hover {
  background-color: var(--alter-color);
}

.map-close {
  background-color: var(--main-color);
  color: white;
}

.map-close:hover {
  background-color: var(--alter-color);
  color: white;
}

.highlight {
  background-color: var(--main-bg-color);
}

.vh-30 {
  height: 30vh;
}

.border-emg {
  border: 1px solid var(--main-color);
  border-radius: 0.25rem;
}

.border-emg:hover {
  border: 2px solid var(--main-color);
  border-radius: 0.25rem;
}

.inner-shadow-emg {
  padding-bottom: 0.1rem;
  -webkit-box-shadow: inset 0px -1px 0px 0px var(--main-color);
  -moz-box-shadow: inset 0px -1px 0px 0px var(--main-color);
  box-shadow: inset 0px -2px 0px 0px var(--main-color);
}

.inner-line-emg {
  padding-bottom: 0.2rem;
  -webkit-box-shadow: inset 0px -2px 0px 0px var(--main-color);
  -moz-box-shadow: inset 0px -2px 0px 0px var(--main-color);
  box-shadow: inset 0px -2px 0px 0px var(--main-color);
}

.nav-link.active,
.nav-item.show .nav-link-emg {
  border-color: white !important;
  padding-bottom: 0.7rem;
  -webkit-box-shadow: inset 0px -1px 0px 0px var(--main-color);
  -moz-box-shadow: inset 0px -1px 0px 0px var(--main-color);
  box-shadow: inset 0px -1px 0px 0px var(--main-color);
}
.nav-tabs {
  border: none !important;
}

.nav-link-emg {
  color: black !important;
}
.nav-item:hover {
  -webkit-box-shadow: inset 0px -1px 0px 0px var(--main-color);
  -moz-box-shadow: inset 0px -1px 0px 0px var(--main-color);
  box-shadow: inset 0px -1px 0px 0px var(--main-color);
}
.card-deck {
  margin-right: auto;
  margin-left: auto;
}
.card-emg {
  transition: all 0.5s ease;
  padding: 0.5rem;
  border-radius: 0;
  border: 1px solid transparent;
}

.card-emg-dark {
  padding: 0.5rem;
  border-radius: 0;
  border: none;
  background-color: var(--main-bg-color);
}

.m--7 {
  margin-top: -7px;
}

label {
  color: var(--main-color);
}

.subscribe-button {
  background-color: var(--main-color);
  color: white;
}

.subscribe-button:hover {
  background-color: var(--alter-color);
  color: white;
}

.emg-button {
  background-color: var(--main-color);
  border: none;
  border-radius: 5px;
  color: white;
  padding: 0 0.2rem 0 0.2rem;
}
.home-card-img {
  height: 20rem;
  object-fit: cover;
}

.card-link-emg {
  margin-left: 0.1rem;
}

.card-link + .card-link {
  margin-left: 0.5rem;
}

.checked {
  color: orange;
}

.p-customers {
  margin-top: 0;
  margin-bottom: 0;
}

.color-white {
  color: white;
}

.thumbnails {
  max-width: 15rem;
}

.opacity-8 {
  opacity: 0.7;
}

.pr-25 {
  padding-right: 2.5rem;
}

.opacity-1 {
  opacity: 1;
}

.emg-button:hover {
  background-color: var(--alter-color);
  cursor: pointer;
}

.vh-50 {
  min-height: 20rem !important;
}

.project-title-container {
  background-color: white;
}

.project-body {
  padding: 0;
}
.white-emg {
  color: white;
}

.pt-10 {
  padding-top: 5rem;
}

.pt-8 {
  padding-top: 4rem;
}

@media only screen and (max-width: 2500px) {
  .wt {
    width: 15% !important;
  }
}

@media only screen and (max-width: 768px) {
  .wt {
    width: 25% !important;
  }
}

.career-tab {
  text-decoration: none !important;
}

.career-date {
  font-size: 0.8rem;
}

.navbar {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
}

@media only screen and (min-width: 768px) {
  .logo {
    width: 30% !important;
  }
}

.news-link {
  color: white;
}

.career {
  border: 1px solid rgba(0, 0, 0, 0.125) !important;
  border-radius: 0.25rem !important;
}

.news-link:hover {
  color: #78c039;
}

.home-news-link {
  color: black;
  text-decoration: none;
}

.slick-prev:before {
  margin-left: -42px;
  color: var(--alter-color) !important;
}

.slick-next:before {
  color: var(--alter-color) !important;
}

.page-item.active > button {
  background-color: var(--alter-color) !important;
  border-color: var(--alter-color) !important;
}

.home-news-link:hover {
  color: var(--main-color);
  text-decoration: none;
}

.item-title:hover {
  color: var(--main-color);
}

.pl-03 {
  padding-left: 0.3rem;
}

.pl-04 {
  padding-left: 0.4rem;
}

.p-04 {
  padding: 0.4rem;
}
.map {
  border: 2px solid var(--main-color);
  width: 100%;
  height: 100%;
}
@media (min-width: 576px) {
}

@media (min-width: 768px) {
}

@media (min-width: 992px) {
  .news-card {
    max-width: 220px;
  }
}

@media (min-width: 1200px) {
  .out-img {
    display: block;
    max-width: 180px;
    max-height: 240px;
    width: auto;
    height: auto;
  }

  .news-card {
    max-width: 265px;
  }

  .col-2-4 {
    flex: 0 0 20%;
    max-width: 20%;
  }
}

a {
  text-decoration: none !important;
  color: inherit;
}

.dropdown:hover .dropdown-menu {
  display: block;
}
